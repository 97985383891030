import { graphql } from 'gatsby';
import React from 'react';

import AboutSeries from '../components/AboutSeries';
import Layout from '../components/Layout';

import { Provider as ContextProvider } from '../context';

export const data = graphql`
  query {
    page: markdownRemark(
      fileAbsolutePath: { regex: "/content/pages/about-series.md$/" }
    ) {
      ...AboutSeries
    }
  }
`;

const AboutSeriesPage = ({ data: { page } }) => (
  <ContextProvider value={{}}>
    <Layout logoColor="black">
      <AboutSeries {...page} />
    </Layout>
  </ContextProvider>
);

export default AboutSeriesPage;
