import { css } from '@emotion/react';

import { bodySpacing, fluid, mq } from '../../style-tokens';

export const contentContainer = css`
  background-color: white;
  min-height: 100vh;
  padding: 4rem ${bodySpacing} ${bodySpacing} ${bodySpacing};
`;

const logoBase = css`
  display: block;
  margin-bottom: ${fluid(1, 2)};
  margin-top: ${fluid(1, 2)};
`;

export const logo = css`
  ${logoBase}
  width: 50%;

  @media ${mq.phone} {
    width: 25%;
  }
`;

export const logoLarge = css`
  ${logoBase}
  width: 75%;

  @media ${mq.phone} {
    width: 50%;
  }
`;
