import { graphql } from 'gatsby';
import React from 'react';
import { useTitle } from 'hooked-head';

import Columns from '../Columns';
import Headline from '../Headline';
import HTML from '../HTML';

import Logo1 from '../../../static/icons/logo-1.svg';
import Logo2 from '../../../static/icons/logo-2.svg';
import Logo3 from '../../../static/icons/logo-3.svg';
import Logo4 from '../../../static/icons/logo-4.svg';
import Logo5 from '../../../static/icons/logo-5.svg';

import { contentContainer, logo, logoLarge } from './aboutSeries.style';

export const fragment = graphql`
  fragment AboutSeries on MarkdownRemark {
    frontmatter {
      title
    }

    html
  }
`;

const AboutSeries = ({ frontmatter: { title }, html }) => {
  useTitle(title);

  return (
    <Columns>
      <div css={contentContainer}>
        <Headline theme="h2" heavy>
          {title}
        </Headline>

        <HTML html={html} />

        <a href="https://www.zentrum-für-demokratie.de/">
          <Logo1 css={logo} />
        </a>

        <a href="https://www.pfefferwerk.de/moskito/">
          <Logo2 css={logo} style={{ marginLeft: '-0.35rem' }} />
        </a>

        <a href="http://demokratie-in-der-mitte.de/">
          <Logo3 css={logoLarge} />
        </a>

        <a href="https://licht-blicke.org/">
          <Logo4 css={logo} />
        </a>

        <a href="https://koordinierungsstelle-mh.de/">
          <Logo5 css={logo} />
        </a>
      </div>
    </Columns>
  );
};

export default AboutSeries;
